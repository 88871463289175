// eslint-disable-next-line import/prefer-default-export

export const sensorList = [
  { label: '온도 센서 (Autonics THD)', value: 'temperature' },
  { label: '습도 센서 (Autonics THD)', value: 'humidity' },
  { label: '온도 센서 (JXCT THD)', value: 'temp_jxct' },
  { label: '습도 센서 (JXCT THD)', value: 'humi_jxct' },
  { label: '우적 센서 (Board Input Type) ', value: 'rain' },
  /* { label: '제상 센서', value: 'freeze' }, */
  { label: '황화수소 (JXCT H2O)', value: 'hs2' },
  { label: '암모니아 (JXCT NH3)', value: 'nh3' },
  { label: '이산화탄소 (JXCT CO2)', value: 'co2' },
  { label: '암모니아 (Gas DnA NH3)', value: 'nh3_gd' },
  { label: '이산화탄소 (Gas DnA CO2)', value: 'co2_gd' },
  { label: '풍향 센서 (JXCT)', value: 'wd_jxct' },
  { label: '풍속 센서 (JXCT)', value: 'ws_jxct' },
  { label: '-', value: '-', disable: true },
]

export function getSensorLabel(value) {
  const result = sensorList.find(el => value === el.value)
  if (result === undefined) {
    return ''
  }
  return result
}
