<template>
  <b-card>
      <!-- <h3 class="d-flex flex-column" style="text-align:center">
      <strong>사용자 정보</strong>
      </h3> -->
    <!-- Media -->

    <!-- User Info: Input Fields -->
    <b-form>
      <b-row
      >

        <!-- Field: Name -->
        <b-col
          cols="12"
          md="12"
        >
        <!-- <b-form-group
            style="width:100%; float:left"
            label="로그인 아이디"
            label-for="phone"
          > -->
          <h2 style="border: outset; text-align: center; border-radius: 1rem; background-color: #E6E8E6">" {{ userData.userInfo.phone}} " 의 사용자 정보</h2>
            <!-- <b-form-input
              id="name"
              v-model="userData.userInfo.phone"
              disabled
            /> -->
         <!--  </b-form-group> -->
          <b-form-group
            style="width:49%; float:left"
            label="사용자 이름"
            label-for="name"
          >
            <b-form-input
              id="name"
              v-model="userData.userInfo.name"
              placeholder="필수"
            />
          </b-form-group>
          <b-form-group
            :disabled="!roleDisabled"
            style="width:49%; float:right"
            label="담당자"
            label-for="user-manager"
          >
              <!-- :reduce="val => val.value" -->
            <v-select
              v-model="manager"
              :options="managerOptions"
              :reduce="val => val.value"
              :clearable="false"
              :disabled="roleDisabled"
              input-id="user-manager"
            />
          </b-form-group>
        </b-col>
        <!-- Field: Address -->
        <b-col
          cols="12"
          md="12"
        >
          <b-form-group
            label="주소"
            label-for="address"
          >
            <b-form-input
              id="address"
              v-model="userData.userInfo.address"
              placeholder="선택"
            />
          </b-form-group>
        </b-col>
<!-- Field: lat, lon -->
        <b-col
          cols="12"
          md="12"
        >
          <b-form-group
            style="width:49%; float:left"
            label="위도(lat)"
            label-for="lat"
          >
            <b-form-input
              id="lat"
              v-model="userData.lat"
              placeholder="선택"
            />
          </b-form-group>
          <b-form-group
            style="width:49%; float:right"
            label="경도(lon)"
            label-for="lon"
          >
            <b-form-input
              id="lon"
              v-model="userData.lon"
              placeholder="선택"
            />
          </b-form-group>
        </b-col>
        
        <b-col
          cols="12"
          md="12"
        >
        <!-- Field: Status -->
          <b-form-group
            style="width:49%; float:left"
            label="접근권한"
            label-for="user-status"
          >
            <v-select
              v-model="userData.userInfo.status"
              :options="statusOptions"
              :reduce="val => val.value"
              :clearable="false"
              input-id="user-status"
            />
          </b-form-group>
          <!-- Field: Role -->
          <b-form-group
            style="width:49%; float:right"
            label="권한부여"
            label-for="user-role"
          >
            <v-select
              v-model="userData.userInfo.role"
              :options="roleOptions"
              :reduce="val => val.value"
              :clearable="false"
              :disabled="roleDisabled"
              input-id="user-role"
            />
          </b-form-group>
        </b-col>
        <!-- Field: Manager -->
        <b-col
          
          cols="12"
          md="3"
        >
          
        </b-col>

        

        
        
        <!-- Field: Server url -->
        <b-col
          v-if="userData.userInfo.role === 'customer'"
          cols="12"
          md="12"
        >
          <b-badge 
            v-if="connect"
            style="float:right"
            variant="success" class="mb-1 mb-sm-0 border float-md-right">
            <feather-icon icon="CheckIcon" /> 연결 확인
          </b-badge>
          <b-badge 
            v-else
            style="float:right"
            variant="danger" class="mb-1 mb-sm-0 border float-md-right">
            <feather-icon icon="AlertCircleIcon" /> 연결 끊김
          </b-badge>
          <b-form-group
            label="컨트롤 서버 URL = http://e-farm.iptime.org:10000 "
            label-for="url"
          >
            <b-form-input
              id="url"
              v-model="userData.url"
              placeholder="http://e-farm.iptime.org:10000"
            />
          </b-form-group>
        </b-col>

        <!-- Field: Memo -->
        <b-col
          cols="12"
          md="12"
        >
          <b-form-group
            label="메모"
            label-for="memo"
          >
            <b-form-textarea
              id="memo"
              v-model="userData.userInfo.info"
              placeholder="선택"
            />
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
          style=""
        >
          <b-form-group
            class=""       
          >
            <b-form-checkbox 
              v-model="dataAccept"
              switch
              > 
              <!-- disabled -->
             
              <div v-show="dataAccept" id="box" style="text-align: center;"><!-- class="box visuallyhidden hidden" -->
                <b style="color:green"> 정보 연계 진행중 </b>
              </div>
              <div v-show="!dataAccept">
                <b style="color:red">정보 연계 해제중</b>
              </div>
            </b-form-checkbox>
            <h4 style="color:blue;text-align: center;" >연계 정보 등록 및 수정 후 [ 저장 ]</h4> 
            <div
              v-if="dataAccept" 
              style="padding: 1rem;border:ridge"   
            >
            
              <b-input-group        
                  prepend="스마트팜 아이디"
                  class="mt-1">
                  <b-form-input 
                    v-model="userData.dataIntegration.make_id"
                    placeholder="필수 입력 정보"/>
                    
                </b-input-group>
                
              <b-input-group            
                prepend="축산업 등록번호"
                class="mt-1">
                  <b-form-input 
                    v-model="userData.dataIntegration.lsind_regist_no"
                    placeholder="필수 입력, cf) QHISP (대문자)"/>
              </b-input-group>
              <tr>
                <td>
                  <b-input-group            
                    prepend="축사 종류"
                    class="mt-1">
                      <b-form-select
                        v-model="userData.dataIntegration.item_code"
                        :options="customSelectType"
                      >
                      </b-form-select>
                  </b-input-group>
                </td>
                <td>
                  <b-input-group            
                    prepend="축사 유형"
                    class="mt-1">
                        <b-form-select
                          v-model="userData.dataIntegration.stall_type_code"
                          :options="customStallSelectType"
                        >
                        </b-form-select>
                  </b-input-group>
                </td>
              </tr>
              

              
                
              <hr class="solid">
          
              <div v-if="dataCollections.length === 0">
                <b style="color:coral">연계 가능한 장비가 없습니다.</b>
              </div>
              <div v-else>
                <b>정보 연계 목록 선택란</b>
                
                <template>
                <div style="padding: 1em">
                  <b-table 
                  :items="dataCollections" 
                  :fields="tableFields" 
                  responsive="sm">
                    <template 
                    #cell(setting)="row"
                    >
                      <!-- `data.value` is the value after formatted by the Formatter -->
                      <feather-icon 
                        icon="FileTextIcon"
                        @click="developing(row)"
                        style="color:darkorange;"
                        v-if="row.item.type === 'feeder'"
                        />
                        <b-modal
                        :ref="`modalPage_${row.item.id}`" 
                        header-bg-variant="success"
                        title-tag="h4"
                        ok-only
                        no-close-on-backdrop
                        ok-title="돌아가기"
                        ok-variant="secondary"
                        button-size='sm'
                        :title="`'${row.item.name}' 사료량 평균 측정`">
                          <p class="mt-1">Btn Space</p>
                        <hr>
                          <b-table
                            :items="feedList"
                            :fields="feedFields"
                            hover
                            select-mode="multi"
                            selectable
                            @row-selected="onRowSelected"
                            style="text-align: center; word-break: break-all;"
                          >
                        
                          <template #cell(selected)="{ rowSelected }">
                            <template v-if="rowSelected">
                              <span aria-hidden="true" style="color: blue;">&check;</span>
                              <span class="sr-only">Selected</span>
                            </template>
                            <template v-else>
                              <span aria-hidden="true">&nbsp;</span>
                              <span class="sr-only">Not selected</span>
                            </template>
                          </template>
                          </b-table>
                    </b-modal>
                </template>
                <template #cell(check)="row">
                      <!-- <b-form-checkbox 
                      v-if="row.item.type === 'feeder'"
                      v-model="row.item.active"
                      disabled
                      switch>
                      </b-form-checkbox> -->
                      <!-- v-else -->
                      <b-form-checkbox 
                      v-model="row.item.active"
                      switch>
                      </b-form-checkbox>

                    </template>
                  </b-table>
                  
                </div>
              </template>
              <p>※ 센서가 장착된 제어기 종류의 장비가 존재하면 센서 정보 연계는 생략합니다. ※</p>
              </div>
            </div>
          </b-form-group>          
        </b-col>
        
      </b-row>
      
    </b-form>
    <b-col
    cols="12">
      <b-button
        variant="primary"
        style="width: -webkit-fill-available"
        :block="$store.getters['app/currentBreakPoint'] === 'xs'"
        @click="updateUser"
      >
        저장
      </b-button>
      <hr>
      <b-button
        variant="danger"
        style="width: -webkit-fill-available"
        :block="$store.getters['app/currentBreakPoint'] === 'xs'"
        @click="checkDelete"
      >
        삭제
      </b-button>
      
    </b-col>
    <!-- Action Buttons -->
    
    
  </b-card>
</template>

<script>
import {
  BButton, BAvatar, BRow, BCol, BTable, BFormGroup, BFormInput, BForm, BCard, BFormTextarea, BBadge, BFormCheckbox, BInputGroup, BFormSelect 
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { avatarText } from '@core/utils/filter'
import vSelect from 'vue-select'
import store from '@/store'
import router from '@/router'
import { getUserData } from '@/auth/utils'
import userListTable from '../user-list/userListTable'
export default {
  components: {
    BTable,
    BButton,
    BAvatar,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BFormTextarea,
    BCard,
    vSelect,
    BBadge,
    BFormCheckbox,
    BInputGroup,
    BFormSelect 
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      customSelectType: [
        { text: '--- 축사 종류를 선택하여 주세요 ---', value: 'undefined'},
        { text: '한우', value: 'W00'},
        { text: '낙농', value: 'D00'},
      ],
      userData: store.state.users.user,
      roleDisabled: getUserData().role !== 'admin',
      managerPower: getUserData().role === 'manager',
      connect: false,
      managerOptions: [],
      manager: '',
      bManager: '',
      dataCollections: [],
      selected: [],
      sensorList: null,
      dataAccept: false,
      tableFields: [
        {key: 'name', label: '장비 이름'},
        {key: 'check', label: '연계'},
      ],
      feedList: [],
      feedFields: [{key: 'amount', label: '급이량'}, {key: 'feeding_time', label: '급이 시간(분)'}, {key: 'start_time', label: '동작 시간'}, {key: 'selected', label: '선택'}],
      selected: [],
      interval: '',
      controlDevices: [
          'fan_control', // 혜송
          'fan_control_sj', // 서진
          'inverter_control_sj', // 인버터 서진
      ]

      //dataTrans: store.state.users.user.dataIntegration
    }
  },
  destroyed() {
    //clearInterval(this.interval)
  },
  setup() {
    const { resolveUserRoleVariant } = userListTable()
    const roleOptions = [
      //{ label: '관리자', value: 'admin' },
      { label: '매니저', value: 'manager' },
      { label: '고객', value: 'customer' },
    ]

    const statusOptions = [
      { label: '허용', value: 'active' },
      { label: '불가', value: 'inactive' },
    ]

    return {
      resolveUserRoleVariant,
      avatarText,
      roleOptions,
      statusOptions,
    }
  },
  created() {
    this.initValue()
    this.getManagers()
    this.setControlServer()
    //this.getDTEquipment(this.userData.dataIntegration.accept)
    /* this.interval = setInterval(() => {
      this.intervalBox()
    }, 1000); */
    this.dataAccept = this.userData.dataIntegration.accept
  },
  computed: {
    customStallSelectType: function() {
      if(this.userData.dataIntegration.item_code === undefined || this.userData.dataIntegration.item_code === null || this.userData.dataIntegration.item_code === '') return [{ text: '--- 축사 종류를 선택하여 주세요 ---', value: 'undefined'}]
      const type = this.userData.dataIntegration.item_code.split('')[0]
      function selectType (type) {
        switch (type) {
          case 'W': return '한우'
          case 'D': return '낙농'
          default: return '축사'
        }
      }
      return [
        { text: `--- ${selectType(type)} 종류를 선택하여 주세요 ---`, value: 'undefined'},
        { text: '우사', value: `S${type}01`},
        { text: '착유장', value: `S${type}02`},
        { text: '축사 외부', value: `SC01`},
      ]
    },
  },
  watch: {
    
    dataAccept: function () {
      if(this.dataAccept) {
        //alert('정보연계 등록은 현재 개발 중인 기능이므로\n실제 작동하지 않습니다. \n이용에 참고 부탁드립니다. ')
        this.getDTEquipment(this.dataAccept)
      } else {
        this.userData.dataIntegration.accept = this.dataAccept
      }
        //this.dataAccept = this.userData.dataIntegration.accept
        /* if(this.userData.dataIntegration === null 
        || this.userData.dataIntegration === undefined) {
          this.dataAccept = false
        } else {
          this.dataAccept = 
        } */
        
        //console.log('refresh dataIntegration', this.dataAccept)
    },
  },
  methods: {
    
    /* 
    // Protect equal equipment function 
    checkSensorCount (row) { 
      for(let data of this.dataCollections) {
        console.log(row)
        if(data.type === row.item.type && data.id !== row.item.id) {
          row.item.active = true
          data.active = false
        }
      }
    }, */
    intervalBox () {
      let box = document.getElementById('box')
      if (box.classList.contains('hidden')) {
        box.classList.remove('hidden');
        setTimeout(function () {
          box.classList.remove('visuallyhidden');
        }, 20);
      } else {
        box.classList.add('visuallyhidden');    
        box.addEventListener('transitionend', function(e) {
          box.classList.add('hidden');
        }, {
          capture: false,
          once: true,
          passive: false
        });
      }
    },
    setControlServer () {
      this.userData.url = (this.userData.url === undefined)? `http://e-farm.iptime.org:10000`: this.userData.url
    },
    resetVari(variable) {
      if(variable === 'ic') this.userData.dataIntegration.item_code = ''
      return this.userData.dataIntegration.stall_type_code = ''
      
    },
    setFeedAmount(item) {
      console.log(this.feedList)
      /* let amount = prompt(`${item.start_time}의 급이량을 입력해주세요`)
      if(amount === null || amount === 'null') {
        let idx = this.feedList.find(e=>e.id === item.id)
        this.fee.splice(idx, 1)
        return
      } 
      if(isNaN(amount) || amount === '') return this.setFeedAmount()
      //if(parseInt(amount) !== 'number') this.setFeedAmount()
      item.checked = 'true'
      item.amount = amount */
    },
    onRowSelected(items) {
      this.selected = items
      for(let item of items) {
        if(item.amount === '') this.setFeedAmount(item)
      }
    },
    feedSampleOP() {
      const param = {
        command: newVal,
        commandValue: this.sliderValue,
        feedValue: this.feedValue,
      }
      store.dispatch('button/command', { id: this.buttonItem._id, queryBody: param })
      .then(res => {
        if(res.status === 200){
          this.updateData()
        }         
      }).catch(error => {
        console.log(error)
      })
    },
    async developing(data) {
      alert('Not Supported.')
      //this.feedList = []
      /* store.dispatch('button/getFeedList', { buttonId: data.item.id })
      .then(res => {
        for(let [idx, data] of res.data.entries()) {
          this.feedList.push(data)
        }
        this.$refs[`modalPage_${data.item.id}`].show()
      })
      .catch(err => {
        console.log('dispatch err', err)
      }) */
      

    },
    showUserState() {
      
    },
    movePage(row) {
      alert(row)
    },
    makeDTElist(buttonList, sensorList) {
      this.dataCollections = []
      
      for(let button of buttonList.data) {
        const dteButtonList = [
          'fan',
          'inverter', 
          'fan_control', 
          'fan_control_sj',
          'inverter_control_sj',
          'sprayer',
          'feeder',
          'feeder_1button',
          'plc_feeder',
          'plc_inverter',
        ]
        let idx = dteButtonList.findIndex(e => e === button.type)
        if(idx > -1) {
          let targetButton = this.userData.dataIntegration.type.button.find(e => e.id === button._id)
          this.dataCollections.push({
            id: button._id, 
            type: button.type, 
            name: button.name, 
            active: (targetButton)? targetButton.active : false
          })
        }
        /* if(button.type === 'feeder') {
          if(this.userData.dataIntegration.type === undefined || this.userData.dataIntegration.type['button'].length === 0) this.dataCollections.push({id: button._id, type: button.type, name: button.name, active: false})
          else {
            for(let buttonData of this.userData.dataIntegration.type.button) {
              if(buttonData.id === button._id) this.dataCollections.push({id: button._id, type: button.type, name: button.name, active: (buttonData.active === true)? true: false})
            }
          }
        } */
      }
      for(let sensor of sensorList.data) {
        console.log('sensor', sensor)
        if(sensor.type === 'temperature' || sensor.type === 'humidity') { 
          let targetSensor = this.userData.dataIntegration.type.sensor.find(e => e.id === sensor._id)
          this.dataCollections.push({
            id: sensor._id, 
            type: sensor.type, 
            name: sensor.name, 
            active: (targetSensor)? targetSensor.active : false
          })
        }
      }
      if(this.dataCollections.length < 1) {
      }
     
    },
    async getDTEquipment(currentAccept){
      
      //const dataTransEquipmentType = ['temperature', 'humidity', 'feeder']
      if(this.userData.dataIntegration === undefined) {
        this.userData.dataIntegration = {
          accept: currentAccept,
          lsind_regist_no: '',
          item_code: '',
          make_id: '',
        }
      } else if(!currentAccept){
        this.dataAccept = currentAccept
      } else {
        this.userData.dataIntegration.accept = currentAccept
        this.dataAccept = currentAccept
      }
      this.sensorList = await this.getSensorList()
      this.buttonList = await this.getButtonList()
      this.makeDTElist(this.buttonList, this.sensorList)
      
    },
    async getButtonList () {
      return await store.dispatch('button/fetchButtons', { userId: store.getters['users/getUserId'] })
    },
    async getSensorList () {
      return await store.dispatch('sensor/fetchSensors', { userId: store.getters['users/getUserId'] })
    },
    getManagers() {
      let userData = getUserData()
       store
        .dispatch('users/getCustomers', { id: userData.id })
        .then(response => {     
          for(let user of response.data) {
            if(user.userInfo.role !== 'customer') {     
                this.managerOptions.push({label: user.userInfo.name, value: user._id, role: user.userInfo.role})
                if(user._id === this.userData.manager) {
                  this.manager = user.userInfo.name // 현재 매니저 이름
                  this.bManager = this.userData.manager // 전의 매니저 id
                }
              }
            }
            if(userData.role === 'customer') {
              if(this.userData.manager === undefined || this.managerOptions.findIndex(e=>e.value === this.userData.manager) === -1) 
              this.userData.manager = (this.managerOptions.find(e=>e.role === 'admin')).value
            }
        })
        .catch(err => {
            console.log(err)
        })
    },
    toggleAll(checked) {
      this.selected = checked ? this.deviceLists.slice() : []
    },
    async checkDelete() {
      try {
        let lastDeviceCount = 0
        const farmDataList = await store.dispatch('farm/fetchFarms', { userId: store.getters['users/getUserId'] })
        if(farmDataList.data.length > 0) lastDeviceCount += farmDataList.data.length
        const deviceDataList = await store.dispatch('device/fetchDevices', { userId: store.getters['users/getUserId'] })
        if(deviceDataList.data.length > 0) lastDeviceCount += deviceDataList.data.length
        const buttonDataList = await store.dispatch('button/fetchButtons', { userId: store.getters['users/getUserId'] })
        if(buttonDataList.data.length > 0) lastDeviceCount += buttonDataList.data.length
        const sensorDataList = await store.dispatch('sensor/fetchSensors', { userId: store.getters['users/getUserId'] })
        if(sensorDataList.data.length > 0) lastDeviceCount += sensorDataList.data.length
        if(lastDeviceCount > 0) {
          this.$bvModal.msgBoxOk('사용자의 축사, 버튼, 센서, 보드 등의 정보들을 모두 삭제해주시기 바랍니다.', {
            title: '정보 삭제 요청',
            centered: true,
            })
        } else {
          this.$bvModal
          .msgBoxConfirm('[필수]※ 축사, 버튼, 센서, 보드 정보를 모두 삭제 하셔야합니다. ※ 삭제하면 되돌릴 수 없습니다. 삭제하시겠습니까?', {
            title: '사용자 삭제',
            size: 'sm',
            okVariant: 'danger',
            okTitle: '확인',
            cancelTitle: '취소',
            cancelVariant: 'outline-secondary',
            hideHeaderClose: true,
            centered: true,
          })
          .then(value => {
            if (value === true) {
              store.dispatch('users/deleteUser', { id: router.currentRoute.params.id })
                .then(() => {
                  router.push({ name: 'user-list' })
                }).catch(error => {
                  console.log(error)
                })
            }
          })
        }


      } catch (error) {
        console.log(error)
        this.$bvModal.msgBoxOk('서버 응답 오류입니다. 관리자에게 문의해주세요', {
          title: 'Not Response',
          centered: true,
          }).then(() => {
              
        })
      }
      

      
    },

    async updateUser() {
      if (this.dataAccept && (
        !this.userData.dataIntegration.lsind_regist_no 
      || !this.userData.dataIntegration.item_code 
      || !this.userData.dataIntegration.make_id 
      || !this.userData.dataIntegration.stall_type_code
      )) {
        this.$bvModal.msgBoxOk('정보 연계를 진행하시려면, 모든 정보를 입력하여야 합니다.', {
          title: '사용자 등록 실패',
          centered: true,
          }).then(() => {
              this.$emit('update')
        })
      }
      else {
        if(this.roleOptions.value === 'customer') {
          this.userData.url = this.userData.url.trim()
          if(this.userData.url.indexOf("http://") === -1){
            this.userData.url = 'http://' + this.userData.url
          } 
        }
        let beforeManager = this.bManager
        let currentManagerInfo = this.managerOptions.find(e=>e.label === this.manager || e.value === this.manager)
        if(beforeManager !== this.manager) {
          this.userData.manager = currentManagerInfo.value
          await this.updateManager(currentManagerInfo.value, 
          await this.pushCustomerToManager(currentManagerInfo.value, router.currentRoute.params.id))
          let idx = this.managerOptions.findIndex(async e => e.value === beforeManager)
          if(idx > -1) {
            await this.updateManager(beforeManager, await this.deleteCustomerToManager(beforeManager, router.currentRoute.params.id))
          }
        }
        /* for(let data of this.dataCollections) {
          this.userData.dataIntegration.type.push(data)
          if(data.type)
        } */
        if(this.userData.dataIntegration === undefined) this.userData.dataIntegration = {}
        this.userData.dataIntegration.type = {
          sensor: [], 
          button: []
          }
        for(let data of this.dataCollections) {
            switch (data.type) {
              case 'temperature':
                this.userData.dataIntegration.type.sensor.push(data)
                break;
              case 'humidity':
                this.userData.dataIntegration.type.sensor.push(data)
                break;
              default:
                this.userData.dataIntegration.type.button.push(data)
                break;
            }
        }
        let totalActive = 0
        for(let sensor of this.userData.dataIntegration.type['sensor']) {
          if(sensor.active) totalActive ++ 
        }
        for(let button of this.userData.dataIntegration.type['button']) {
          if(button.active) totalActive ++ 
        }
        if(totalActive === 0) this.userData.dataIntegration.accept = false
        if(this.userData.dataIntegration.accept) {
          /* let targetItem = this.customSelectType.find(e=>e.label === this.userData.dataIntegration.item_code)
          this.userData.dataIntegration.item_code = targetItem.value
          if(targetItem === 'W00') {
            let targetItem1 = this.kCowTypeList.find(e=>e.label === this.userData.dataIntegration.stall_type_code)
            this.userData.dataIntegration.stall_type_code = targetItem1.value
          } else {
            let targetItem1 = this.mCowTypeList.find(e=>e.label === this.userData.dataIntegration.stall_type_code)
            this.userData.dataIntegration.stall_type_code = targetItem1.value
          } */
          this.userData.dataIntegration.make_id = this.userData.dataIntegration.make_id.toLowerCase() || null
          this.userData.dataIntegration.lsind_regist_no = this.userData.dataIntegration.lsind_regist_no.toUpperCase() || null
        }
        await store.dispatch('users/updateUser', { id: router.currentRoute.params.id, queryBody: this.userData })
        router.push({ name: 'user-list' })

      }      
    },
    async pushCustomerToManager (managerId, targetUserId) {
      let pushResult = (await store.dispatch('users/fetchUser', { id : managerId })).data
      if(pushResult.customers.findIndex(e => e === targetUserId) === -1) {
        pushResult.customers.push(targetUserId)
      }
      return pushResult
      
    },
    async deleteCustomerToManager (managerId, targetUserId) {
      let result = await store.dispatch('users/fetchUser', { id : managerId })
      let idx = result.data.customers.findIndex(e => e === targetUserId)
      if(idx > -1) {
        result.data.customers.splice(idx, 1)
      }
      return result.data   
    },
    async updateManager (managerId, managerData) {
      store.dispatch('users/updateUser', { id: managerId, queryBody: managerData })
      .then(() => {
        console.log(`update ${managerId} complete`)
      }).catch(error => {
        console.log(error)
      })
    },


    

    async initValue() {
      let userData = await store.dispatch('users/getUserConnect', { id: router.currentRoute.params.id})
      if(userData.status === 200) this.connect = (userData.data === 'SUCCESS') ? true : false
      else this.connect = false
    },

  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

.box {
  margin: auto;
  transition: all 0.5s linear;
  display: block;
}

.hidden {
  display: hide;
}

.visuallyhidden {
  opacity: 0;
}

</style>
