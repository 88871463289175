<template>
  <!-- Modal -->
  <b-modal
    ref="addButtonModal"
    title="버튼 등록"
    ok-title="등록"
    cancel-title="취소"
    cancel-variant="outline-secondary"
    no-close-on-backdrop
    centered
    @ok="create(id)"
  >
    <!-- @ok="id ? modify() : create()" -->
    <b-form>
      <b-form-group
        label="농장 이름"
        label-for="farmName"
      >
        <v-select
          id="farmName"
          v-model="farmName"
          :options="farmOptions"
          :searchable="false"
          :clearable="false"
        />
      </b-form-group>
      <b-form-group
        label="버튼 타입"
        label-for="button-type"
      >
        <v-select
          id="button-type"
          v-model="type"
          :options="buttonOptions"
          :searchable="false"
          :clearable="false"
        />
      </b-form-group>
      <b-form-group
        label="버튼 이름"
        label-for="button-name"
      >
        <b-form-input
          id="button-name"
          v-model="name"
          type="text"
          placeholder="이름"
        />
      </b-form-group>
      <b-form-group
        v-if="type.value === 'farm_1'"
        label="신호 타입"
        label-for="signal-type"
      >
        <v-select
          id="signal-type"
          v-model="signalTypeSelected"
          :options="signalTypeOptions"
          :searchable="false"
          :clearable="false"
        />
      </b-form-group>
      <b-form-group
        v-if="type.value === 'farm_1'"
        label="Relay 사용 개수 (1 ~ 3)"
        label-for="relay-num"
      >
        <b-form-spinbutton
          id="relay-num"
          v-model="relayNum"
          :disabled="!relayNumAble"
          min="1"
          max="3"
        />
      </b-form-group>
      <b-row
        v-if="signalTypeSelected.value==='trigger'"
      >
        <b-col md="6">
          <b-form-group
            label="동작 시간"
            label-for="signal-time"
            class="text-center"
          >
            <b-form-spinbutton
              id="signal-time"
              v-model="signalTime"
              min="1"
              step="1"
              cols="6"
            />
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group
            label="단위"
            label-for="signal-time-unit"
            class="text-center"
          >
            <v-select
              id="signal-time-unit"
              v-model="signalTimeUnit"
              :options="timeOptions"
              :clearable="false"
              :searchable="false"
            />
          </b-form-group>
        </b-col>
      </b-row>

      <!-- <b-form-group
      v-if="this.type.value!=='inverter'"
        label="DDNS"
        label-for="ddns"
      >
        <b-form-input
          id="ddns"
          v-model="ddns"
          type="text"
          placeholder=""
        />
      </b-form-group>
      <b-form-group
      v-if="this.type.value!=='inverter'"
        label="Port"
        label-for="port"
      >
        <b-form-input
          id="port"
          v-model="port"
          type="number"
          placeholder=""
        />
      </b-form-group> -->
      <div 
      v-if="this.registURLdevices.findIndex(e => e === this.type.value) > -1">
      <b-form-group
      
        label="URL"
        label-for="url"
      >
      <span style="color: red">※ 등록 시 주의사항 :: http:// 제외한 ddns : port ※</span>
      
        <b-form-input
          id="URL"
          v-model="url"
          type="text"
          placeholder="ex) ewha-api.iptime.org:4660"
        />
      </b-form-group>
      <!-- v-if="this.type.value==='inverter' || this.type.value==='inverter_hd'" -->
      <b-form-group
        label="Slave ID"
        label-for="slaveId"
      >
      <span style="color: red">※필수 등록※</span>
        <b-form-input
          id="Slave ID"
          v-model="slaveId"
          type="number"
          required
        />
      </b-form-group>
      <!-- v-if="this.type.value==='inverter' || this.type.value==='inverter_hd'" -->
      
      </div>
      <b-form-group
        label="Plc 등록 Fan Or 사료급이기"
        label-for="address"
        v-if="this.type.value==='plc_fan_jnk'"
      >
        <b-form-input
          id="address"
          v-model="address"
          type="number"
          placeholder="1"
        />
      </b-form-group>
      <b-form-group
        label="인풋 신호 번호"
        label-for="address"
        v-if="this.type.value==='feeder'"
      >
        <b-form-input
          id="address"
          v-model="address"
          type="number"
          placeholder="0"
        />
      </b-form-group>
    </b-form>
  </b-modal>
</template>

<script>
import {
  BModal, VBModal, BForm, BFormGroup, BFormSpinbutton, BFormInput, BRow, BCol,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import {
  buttonList, signalList, getButtonLabel, getSignalLabel,
} from './utils'

export default {
  components: {
    BRow,
    BCol,
    BModal,
    BForm,
    BFormGroup,
    BFormSpinbutton,
    BFormInput,
    vSelect,
  },
  directives: {
    'b-modal': VBModal,
  },
  watch: {
    signalTypeSelected() {
      this.relayNumAble = false
      if(this.signalTypeSelected.value === 'toggle') this.relayNum = 1
      if(this.signalTypeSelected.value === 'trigger') this.relayNumAble = true
      if(this.signalTypeSelected.value === 'complex') this.relayNum = 2

    }
  },
  data() {
    return {
      type: '',
      signalTimeUnit: { label: '초', value: 'sec' },
      signalTime: 1,
      timeOptions: [
        { label: '초', value: 'sec' },
        { label: '분', value: 'min' },
      ],
      ddns: '',
      port: '',
      url: '',
      slaveId: '',
      address: '',
      name: '',
      buttonOptions: buttonList,
      signalTypeSelected: '',
      signalTypeOptions: signalList,
      farmOptions: [],
      farmName: '',
      workingTime: 0,
      relayNum: 1,
      relayNumAble: true,
      id: null,
      registURLdevices: [
        'inverter', 
        'inverter_hd', 
        'inverter_hz', 
        'fan_control', 
        'fan_control_sj', 
        'inverter_control_sj',
        'cooler_sh',
        'plc-fan',
        'plc_fan_jnk',
        'plc_manure_machine',
        'curtain_sj',
        'plc_feeder',
        'plc_inverter',
        'plc_inverter_iv',
        'plc_curtain'
      ]
    }
  },
  methods: {
    showModal() {
      this.id = null
      this.farmOptions = this.$store.getters['farm/getFarmSelect']
      this.type = ''
      this.signalTime = 1
      this.signalTimeUnit = { label: '초', value: 'sec' }
      this.ddns = ''
      this.port = ''
      this.url = ''
      this.slaveId = ''
      this.address = ''
      this.name = ''
      this.signalTypeSelected = ''
      this.farmName = this.farmOptions[0]
      this.relayNum = 1
      this.$refs.addButtonModal.show()
      //console.log(this.farmOptions)
    },
    editModal(item) {
      this.farmOptions = this.$store.getters['farm/getFarmSelect']
      this.signalTypeOptions = signalList
      this.id = item._id
      this.farmName = this.farmOptions.filter(el => item.farmId._id === el.value)
      this.name = item.name
      this.type = getButtonLabel(item.type)
      this.ddns = item.ddns
      this.port = item.port
      this.url = item.url
      this.address = item.address
      this.slaveId = item.slaveId
      this.relayNum = item.buttonSetting.relayCount
      this.signalTypeSelected = getSignalLabel(item.buttonSetting.signalType)

      if (item.signalTime / 60 > 1) {
        this.signalTime = item.signalTime / 60
        this.signalTimeUnit = { label: '분', value: 'min' }
      } else {
        this.signalTime = item.signalTime
        this.signalTimeUnit = { label: '초', value: 'sec' }
      }

      this.$refs.addButtonModal.show()
    },

    selectRelayCount(type) {

      switch (type) {
        case 'light':
        case 'fan':
        case 'inverter':
        case 'inverter_hd':
        case 'inverter_hz':
        case 'feeder_1button':
        case 'hydraulic_complex':
        case 'sprayer': 
        case 'car_disinfector_toggle': 
        case 'curtain_toggle': return 1

        case 'curtain':
        case 'ceiling_2button':
        case 'feeder':
        case 'hydraulic_trigger2':
        case 'feeder_necklace':
        case 'sprayer_trigger': 
        case 'car_disinfector_trigger':
        case 'fan_setting': return 2

        case 'ceiling':
        case 'hydraulic_trigger':
        case 'ceiling_reverse': return 3
        default: return 1
      }
    },

    create(id) {
      let farmId
      if(this.farmName.length >= 1) 
      { farmId = this.farmName[0].value } 
      else 
      { farmId = this.farmName.value }
      if (farmId ) {
        let time = this.signalTime
        if (this.signalTimeUnit.value === 'min') {
          time *= 60
        }
        this.relayNum = (this.type.value === 'farm_1')?this.relayNum :this.selectRelayCount(this.type.value)
        if(this.name === '' 
        || this.type.value === '') {
          this.$bvModal.msgBoxOk(`버튼 등록에 필요한 정보를 입력하지 않았습니다. 
          모든 정보를 입력해주세요.`, {
          title: `버튼 ${id? '수정': '추가'} 실패`,
          centered: true,
          okVariant: 'warning',
          })
        } else {
          const payload = {
            userId: this.$store.getters['users/getUserId'],
            farmId: farmId,
            name: this.name,
            type: this.type.value,
            signalTime: time,
            ddns: this.ddns,
            port: this.port,
            url: this.url,
            slaveId: this.slaveId,
            address: this.address,
            'buttonSetting.relayCount': this.relayNum,
            'buttonSetting.signalType': this.signalTypeSelected.value,
          }
          let request = id? { id: this.id, queryBody: payload }: { queryBody: payload }
          let requestPath = id? 'button/updateButton': `button/createButton`
          this.$store.dispatch(requestPath, request)
            .then(() => {
              this.$emit('update')
            })
            .catch(error => {
              console.log(error)
              this.$bvModal.msgBoxOk(`버튼이 ${id? '수정': '추가'}되지 않았습니다`, {
                title: `버튼 ${id? '수정': '추가'} 실패`,
                centered: true,
              }).then(() => {
                this.$emit('update')
              })
            })
        }
      }
      else {
        this.$bvModal.msgBoxOk('축사가 선택되지 않았습니다', {
          title: `버튼 ${id? '수정': '추가'} 실패`,
          centered: true,
          okVariant: 'danger',
          }).then(() => {
              this.$emit('update')
          })
      }
    },

    /* modify() {
      let time = this.signalTime
      if (this.signalTimeUnit.value === 'min') {
        time *= 60
      }

      const payload = {
        userId: this.$store.getters['users/getUserId'],
        farmId: this.farmName.value,
        name: this.name,
        type: this.type.value,
        signalTime: time,
        ddns: this.ddns,
        port: this.port,
        url: this.url,
        slaveId: this.slaveId,
        address: this.address,
        'buttonSetting.relayCount': this.relayNum,
        'buttonSetting.signalType': this.signalTypeSelected.value,
      }

      this.$store.dispatch('button/updateButton', { id: this.id, queryBody: payload })
        .then(() => {
          this.$emit('update')
        })
        .catch(error => {
          console.log(error)
          this.$bvModal.msgBoxOk('버튼이 수정되지 않았습니다', {
            title: '버튼 수정 실패',
            centered: true,
          }).then(() => {
            this.$emit('update')
          })
        })
    }, */
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
