// eslint-disable-next-line import/prefer-default-export

export const buttonList = [
  { label: '통합 제어', value: 'farm_1' },
  { label: '커튼(열림, 정지, 닫힘) 2포트 - [복합제어]', value: 'curtain' },
  { label: '커튼(작동, 정지) 1포트 - [복합제어]', value: 'curtain_toggle' },
  { label: 'PLC(커튼 제어기) -JNK', value: 'plc_curtain' },
  { label: '천장(열림, 정지, 닫힘) 3포트 - [트리거]', value: 'ceiling' },
  { label: '천장(열림, 닫힘, 정지) 3포트 - [트리거]', value: 'ceiling_reverse' },
  { label: '천장(열림, 닫힘) 2포트 - [트리거]', value: 'ceiling_2button' },
  { label: '조명(켜기, 끄기) 1포트 - [토글]', value: 'light' },
  { label: '선풍기(켜기, 끄기) 1포트 - [토글]', value: 'fan' },
  { label: '선풍기(동작) 1포트 - [트리거]', value: 'fan_trigger' },
  { label: '선풍기모드(자동, 수동) 2포트 - [트리거]', value: 'fan_setting' },
  { label: '선풍기 제어기 -(주)서진', value: 'fan_control_sj' },
  { label: '선풍기 제어기 -(주)혜송', value: 'fan_control' },
  { label: '선풍기 제어기 -(주)JNK', value: 'plc_fan_jnk' },
  { label: '인버터 -LS', value: 'inverter' },
  { label: '인버터 -HZ', value: 'inverter_hz' },
  { label: '인버터 -HD', value: 'inverter_hd' },
  { label: '인버터 제어기 -(주)서진', value: 'inverter_control_sj' },
  { label: '인버터(PLC_LS)', value: 'plc_inverter' },
  { label: '인버터(PLC_IV)', value: 'plc_inverter_iv' },
  { label: '사료급이기(동작, 정지) 2포트 - [트리거]', value: 'feeder' },
  { label: '사료급이기(동작) 1포트 - [트리거]', value: 'feeder_1button' },
  { label: 'PLC(사료급이기) -JNK', value: 'plc_feeder' },
  { label: '유압제어기(동작, 정지) 1포트 [토글]', value: 'hydraulic_complex' },
  { label: '유압제어기(동작) 1포트 [트리거]', value: 'hydraulic_1trigger' },
  { label: '유압제어기(동작, 정지) 2포트 [트리거]', value: 'hydraulic_trigger2' },
  { label: '유압제어기(급여, 정지, 닫음) 3포트 - [트리거] ', value: 'hydraulic_trigger' },
  { label: '사료 목걸이(열림, 정지, 닫힘) 2포트 - [복합제어]', value: 'feeder_necklace' },
  { label: '안개분무기(켜기, 끄기) 1포트 - [토글]', value: 'sprayer' },
  { label: '안개분무기(켜기, 끄기) 2포트 - [트리거]', value: 'sprayer_trigger' },
  { label: '차량소독기(켜기, 끄기) 1포트 - [토글]', value: 'car_disinfector_toggle' },
  { label: '차량소독기(켜기, 끄기) 2포트 - [트리거]', value: 'car_disinfector_trigger' },
  { label: '스마트 냉각기 -(주)삼호 [485]', value: 'cooler_sh' },
  { label: '분뇨처리기 - LE솔루션', value: 'plc_manure_machine' },
]

export const signalList = [
  { label: '토글', value: 'toggle' },
  { label: '트리거', value: 'trigger' },
  { label: '복합제어', value: 'complex'}
]

export function getButtonLabel(value) {
  const result = buttonList.find(el => value === el.value)
  if (result === undefined) {
    return ''
  }
  return result
}

export function getSignalLabel(value) {
  const result = signalList.find(el => value === el.value)
  if (result === undefined) {
    return ''
  }
  return result
}
