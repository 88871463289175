<template>
  <!-- Modal -->
  <b-modal
    ref="addDeviceModal"
    title="통신 장비 등록"
    ok-title="등록"
    cancel-title="취소"
    cancel-variant="outline-secondary"
    no-close-on-backdrop
    scrollable
    centered
    @ok="id ? modify() : create()"
  >
    <b-form>
      <b-form-group
        label="농장 이름"
        label-for="farmName"
      >
        <v-select
          id="farmName"
          v-model="farmName"
          :options="farmOptions"
          :clearable="false"
          :searchable="false"
        />
      </b-form-group>
      <b-form-group
        label="통신 장비 이름"
        label-for="deviceName"
      >
        <b-form-input
          id="deviceName"
          v-model="deviceName"
          type="text"
        />
      </b-form-group>

      <div class="divider my-2">
        <div class="divider-text text-primary">
          Network Setting
        </div>
      </div>

      <b-form-group
        label="타입"
        label-for="type"
      >
        <v-select
          id="type"
          v-model="deviceType"
          :options="type_option"
          :clearable="false"
          :searchable="false"
        />
      </b-form-group>
      <b-form-group
      
        label="채널"
        label-for="channel"
      >
        <v-select
          id="channel"
          v-model="channel"
          :options="channel_op"
          :clearable="false"
          :searchable="false"
        />
      </b-form-group>
      <b-form-group
      v-if="this.deviceType!=='가상보드'"
        label="DDNS"
        label-for="ddns"
      >
        <b-form-input
          id="ddns"
          v-model="ddns"
          type="text"
          placeholder=""
        />
      </b-form-group>
      <b-form-group
      v-if="this.deviceType!=='가상보드'"
        label="Port"
        label-for="port"
      >
        <b-form-input
          id="port"
          v-model="port"
          type="number"
          placeholder="10000"
        />
      </b-form-group>
      <b-form-group
      v-if="this.deviceType==='ET0808'"
        label="ID"
        label-for="account"
      >
        <b-form-input
          id="account"
          v-model="account"
          type="text"
          placeholder="admin"
        />
      </b-form-group>
      <b-form-group
      v-if="this.deviceType==='ET0808'"
        label="Password"
        label-for="password"
      >
        <b-form-input
          id="password"
          v-model="password"
          type="text"
          placeholder="············"
        />
      </b-form-group>

    </b-form>
  </b-modal>
</template>

<script>
import {
  BModal, VBModal, BForm, BFormInput, BFormGroup,
} from 'bootstrap-vue'
import vSelect from 'vue-select'

export default {
  components: {
    BModal,
    BForm,
    BFormInput,
    BFormGroup,
    vSelect,
  },
  directives: {
    'b-modal': VBModal,
  },
  data() {
    return {
      farmOptions: [],
      type_option: ['ET0808', '가상보드', 'KC868'],
      deviceType: '',
      channel_option: [8, 16],
      farmName: '',
      deviceName: '',
      channel: '',
      ddns: '',
      port: '',
      account: '',
      password: '',
      serialNum: '',
      id: null,
    }
  },
  computed: {
    channel_op: function () {
      if(this.deviceType === 'ET0808') {
        this.channel = 8
        return this.channel_option = [8]
      } else {
        return this.channel_option = [8, 16]
      }
    }
  },
  methods: {
    showModal() {
      this.farmOptions = this.$store.getters['farm/getFarmSelect']
      this.id = null
      this.farmName = this.farmOptions[0]
      this.deviceName = ''
      this.deviceType = ''
      this.channel = this.channel_option[0]
      this.ddns = ''
      this.port = ''
      this.account = ''
      this.password = ''
      this.serialNum = ''
      this.$refs.addDeviceModal.show()
    },
    editModal(item) {
      this.farmOptions = this.$store.getters['farm/getFarmSelect']
      this.id = item._id
      this.farmName = this.farmOptions.find(el => item.farmId._id === el.value)
      this.deviceName = item.name
      this.deviceType = item.type
      this.channel = item.channel
      this.ddns = item.ddns
      this.port = item.port
      this.account = item.account
      this.password = item.password
      this.serialNum = item.serialNum

      this.$refs.addDeviceModal.show()
    },
    create() {
      if (this.farmName.value) {
        const postBody = {
        userId: this.$store.getters['users/getUserId'],
        farmId: this.farmName.value,
        name: this.deviceName,
        type: this.deviceType,
        channel: this.channel,
        ddns: this.ddns.trim(),
        port: this.port,
        account: this.account,
        password: this.password,
        serialNum: this.serialNum,
        }

        this.$store.dispatch('device/createDevice', { queryBody: postBody })
          .then(() => {
            this.$emit('update')
          })
          .catch(error => {
            console.log(error)
          })
      }
      else {
        this.$bvModal.msgBoxOk('축사가 선택되지 않았습니다', {
          title: '장비 추가 실패',
          centered: true,
          okVariant: 'danger',
          }).then(() => {
              this.$emit('update')
          })
      }
        
    },

    modify() {
      const postBody = {
        userId: this.$store.getters['users/getUserId'],
        farmId: this.farmName.value,
        name: this.deviceName,
        type: this.deviceType,
        channel: this.channel,
        ddns: this.ddns.trim(),
        port: this.port,
        account: this.account,
        password: this.password,
        serialNum: this.serialNum,
      }

      //console.log(postBody)

      this.$store.dispatch('device/updateDevice', { id: this.id, queryBody: postBody })
        .then(res => {
          //console.log(res)
          this.$emit('update')
        })
        .catch(error => {
          console.log(error)
        })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
